import '../empresas.css';
import './admin.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header';



const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}


const Usuarias = () => {
    const [usuarias, setUsuarias] = useState([]);
    const [page, setPage] = useState(1);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarias`;
    const [error, setError] = useState(null);
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        console.log('hola estoy aca')
        fetchData();
    }, []);

    const handleSearch = async() => {
        await fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/filter/${searchTerm}/${page}`, {
            headers:{
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setUsuarias(data);
        })
        .catch(err => {
        
            setError(err);
            console.log('ha ocurrido un error', error);
        })
        }
    const fetchData = async() => {
    await fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/page/${page}`, {
        headers:{
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        }
        })
    .then(response => response.json())
    .then(data => {
        setUsuarias(data);
    })

    .catch(err => {
        
        setError(err);
        console.log('ha ocurrido un error', error);
    })
    }
    console.log(usuarias);
    const handleLoadMore = () => {
        setPage(page + 1);
        if (searchTerm !== "") {
            handleSearch();
        }else{
            fetchData();
        }

        
    };
    const handleLoadLess = () => {
        setPage(page - 1);
        if (searchTerm !== "") {
            handleSearch();
        }else{
            fetchData();
        }
    };
    const handleVerDetalle = (id) => {
        console.log(id);
        navigate(`/detalleUsuaria/${id}`);

    }

    return (
        <div className="WT1">
        <Header />  
        <input
            className='busqueda'
            type="text"
            placeholder="Buscar por Rut, Nombre o Apellido"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch} className='boton-busqueda'>Buscar</button>
        <div className='containerempresas'>
            <div className='textoLogin-admin'>Usuarias WoT</div> 
            <table className='tabla-usuarias'>
                <thead>
                    <tr>
                        <th>RUT</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Ver Detalle</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarias.map((usuaria) => (
                        <tr key={usuaria.id}>
                            <td>{usuaria.rut}</td>
                            <td>{usuaria.nombre}</td>
                            <td>{usuaria.apellido}</td>
                            <td>
                                <button onClick={() => handleVerDetalle(usuaria.id)}>
                                    Ver Detalle
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={handleLoadMore} className='boton-mas'>Ver más</button>
            {page > 1 && (
                <button onClick={handleLoadLess} className='boton-menos'>Ver menos</button>
            )}
        </div>
        <div className='rectangulo'></div>
        <img src={wot24} alt='circulo' className='wot24' />
    </div>
    );



 
    
}

export default Usuarias