import '../empresas.css';
import './admin.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header.js';

const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}


const Usuarias = () => {
    const [usuaria, setUsuaria] = useState([]);
    const [error, setError] = useState(null);
    const id = useParams().id;
    const jwtToken = localStorage.getItem('accessToken');
    useEffect(() => {
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/usuaria/${id}`, {
            headers:{
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
            })
        .then(response => response.json())
        .then(data => {
            setUsuaria(data);
        })
    
        .catch(err => {
            
            setError(err);
            console.log('ha ocurrido un error', error);
        })
    }, []);
    console.log(usuaria);
    return (
        <div className="WT1">
           <Header />
            <div className='containerMatch1'>
            <p className='boldMiPerfil'> {usuaria.nombre} {usuaria.apellido}</p>
            <div class="subcontainerMatchimg">
                <img src={wot25} className='subcontainerMatchimg'/>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Rut: {usuaria.rut} </h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Mail: {usuaria.mail} </h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Universidad: {usuaria.universidad}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Postgrado: {usuaria.postgrado}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Cargo: {usuaria.cargo && usuaria.cargo.cargo}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Industria: {usuaria.industria && usuaria.industria.nombre_industria}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Empresa: {usuaria.empresa_actual}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Cargo Adicional: {usuaria.aditionalCargo && usuaria.aditionalCargo.cargo}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Industria Adicional: {usuaria.aditionalIndustria && usuaria.aditionalIndustria.nombre_industria}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Empresa Adicional: {usuaria.empresa_adicional}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Años Experiencia: {usuaria.aniosExperiencia && usuaria.aniosExperiencia.rango_experiencia_desde} - {usuaria.aniosExperiencia && usuaria.aniosExperiencia.rango_experiencia_hasta}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Experiencia en directorios: {usuaria.experienciaDirectorios}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Formación en alta dirección: {usuaria.altaDireccion}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Intereses: {usuaria.intereses}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Brief: {usuaria.brief}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Linkedin: {usuaria.redesSociales}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Formulario Personalidad: {usuaria.FormularioPersonalidad && usuaria.FormularioPersonalidad.personalidad}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Factor: {usuaria.factor}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Pueblo Originario: {usuaria.nombrePuebloOriginario}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Región Compromiso: {usuaria.regionCompromiso && usuaria.regionCompromiso.nombre}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>País Domicilio: {usuaria.paisDomicilio && usuaria.paisDomicilio.pais}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Región Domicilio: {usuaria.regionActualDomicilio && usuaria.regionActualDomicilio.nombre}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Posibilidad de cambiarse de región: {usuaria.posibilidadCambiarseRegion && usuaria.posibilidadCambiarseRegion.posibilidad}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Disposición Viaje: {usuaria.disposicion_viajar}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Modalidad: {usuaria.tipoModalidad && usuaria.tipoModalidad.tipoModalidad}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Jornada: {usuaria.tipoJornada && usuaria.tipoJornada.tipoJornada}</h>
            </div>
            <div class="subcontainerMatch">
                <h className='normalMiPerfil'>Como conoción WoT: {usuaria.conocioWOT && usuaria.conocioWOT.conocio}</h>
            </div>

            </div>

            <img src={wot24} alt='circulo' className='wot24Empresas' />
            
            
            <div className='rectanguloEmpresas'>
            </div>
            <img src={wot24} alt='circulo' className='wot24Empresas' />
        </div>
    );




  //tengo que hacer llamada al backend con paginacion para tener la info de todas las usuarias 
  // hacer una tabla que las muestre todas y que se pueda filtrar por nombre, rut, etc
  // hacer un boton para ver detalle de cada una
  // hacer barra de busqueda 
  // la tabla va a ser nombre apellido rut y ver detalle 
    
}

export default Usuarias