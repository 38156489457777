import React from 'react';
import './formularioEmpresas.css';
import wot23 from '../iconos/wot24.png';
import { Form} from 'react-final-form';
import { useState } from "react";
import validator from 'validator'
import wot20 from '../iconos/wot20.png';
import ReactDOM from 'react-dom';
import wot_inicio from '../img/brochure wot final iniccio.png';
import Select, { components } from "react-select";
import FormularioEmpresa5 from './formularioEmpresa5';
import FormularioEmpresa3 from './formularioEmpresa3';


const Busqueda5 = () => {
    const[declaracion, setDeclaracion] = useState(false);
    const[terminos, setTerminos] = useState(false);
    const rut = JSON.parse(localStorage.getItem("rutEmpresa"));
    const nombre = JSON.parse(localStorage.getItem("nombreEmpresa"));
    const industria = JSON.parse(localStorage.getItem("industriaEmpresa")); //id 
    const region = JSON.parse(localStorage.getItem("regionEmpresa")); //id
    const anoFundacion = JSON.parse(localStorage.getItem("anoFundacionEmpresa")); //id
    const ingreso = JSON.parse(localStorage.getItem("ingresoEmpresa")); //id
    const empleado = JSON.parse(localStorage.getItem("empleadoEmpresa")); //id 
    const valores = JSON.parse(localStorage.getItem("valoresEmpresa")); 
    const esg = JSON.parse(localStorage.getItem("esgEmpresa"));
    const indicadores = JSON.parse(localStorage.getItem("indicadoresEmpresa"));
    const empresaB = JSON.parse(localStorage.getItem("empresaB"));
    const nombreContacto = JSON.parse(localStorage.getItem("nombreContactoEmpresa"));
    const cargo = JSON.parse(localStorage.getItem("cargoEmpresa"));
    const mail = JSON.parse(localStorage.getItem("mailEmpresa"));
    const [indicadoresImpacto, setIndicadoresImpacto] = useState(false);
    const [politicasEsg, setPoliticasEsg] = useState(false);
    const [empresasB, setEmpresasB] = useState(false);
    const uri = `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/auth/register-company`; //preguntar si meterlos en el env

    //variables para guardar los valores del formulario
    const root = ReactDOM.createRoot(
        document.getElementById('root')
      );
    const handleDeclaracion= (e) => {
        setDeclaracion(e.target.value)
    };
    const handleTerminos= (e) => {
        setTerminos(e.target.value)
    };
    function formularioEmpresa5() {
        root.render(<FormularioEmpresa5/>);
        }
    function formularioEmpresa3() {
        root.render(<FormularioEmpresa3/>);
        }
    const handleFetch = async (uri, infoEmpresaJSON) =>  { await fetch(uri,{
        method: "post", 
        headers: {"Content-type":"application/json"},
        body: infoEmpresaJSON
    }).then(function(response) {
        console.log(response);
        }).catch((error)=>{
            console.error('Error:',error)
        })
    }
    const handleSubmit= async(e) =>{
        if (declaracion.trim() === "" || terminos.trim() === "") {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else{
            if(indicadores === "true"){
                setIndicadoresImpacto(true);
            } else{
                setIndicadoresImpacto(false);
            }
            if(esg === "true"){
                setPoliticasEsg(true);
            } else{
                setPoliticasEsg(false);
            }
            if(empresaB === "true"){
                setEmpresasB(true);
            } else{
                setEmpresasB(false);
            }
            localStorage.setItem("declaracion", JSON.stringify(declaracion));
            localStorage.setItem("terminos", JSON.stringify(terminos));
            var infoEmpresa = {
                email: mail,
                user_name: nombreContacto,
                password: "Empresa123.",
                company_rut: rut,
                company_name: nombre,
                id_industria: parseInt(industria),
                id_fundacion: parseInt(anoFundacion),
                id_ingreso_anual: parseInt(ingreso),
                id_cantidad_empleados: parseInt(empleado),
                valoresEmpresa: valores,
                politicaESG: politicasEsg,
                indicadoresImpacto: indicadoresImpacto,
                empresa_b: empresasB,
                id_region: parseInt(region),
                declaracion: declaracion
            }
            const infoEmpresaJSON = JSON.stringify(infoEmpresa);
            console.log(infoEmpresaJSON);
            await handleFetch( uri , infoEmpresaJSON);
            root.render(<FormularioEmpresa5/>)
        }
    }
    return (
    <div className="WT1">
        <header>
        <nav className='topnavbar1'>
            <a href='/'  ><img src={wot20} alt='logo wot'/></a>
        </nav>
        </header>
        <label className='numero1-busquedas'>1</label>
        <label className='numero2-busquedas'>2</label>
        <label className='numero3-busquedas'>3</label>
        <label className='busqueda-busquedas'>Búsqueda</label>
        <label className='perfilProfesional-busquedas'>Perfil profesional</label>
        <label className='requisitos-busquedas'>Requisitos</label>
        <div className='container-busquedas'>
        <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                         
                        <div className='enviar'>
                            <h3> Antes de enviar...</h3>
                        </div> 
                        <div className='declaracion'>
                            <input type="checkbox" id="declaracion" name="declaracion" value={true} onChange={handleDeclaracion}/>
                            <label for="declaracion">Declaro que los datos ingresados son verídicos</label>
                        </div>
                        <div className='terminos-politicas'>
                        <a href='https://drive.google.com/file/d/1xgzD0NniJmMeO9NEfDdQAwfYpWTzbTQU/view?usp=share_link' className='terminos2'> Términos y Condiciones WoT</a>
                        <label>     </label>
                        <a href='https://drive.google.com/file/d/1Of7cSZ0yoRWYBOcTP08s9g5rIDGtN8TD/view?usp=share_link' className='politicas2'> Política de Privacidad WoT</a>
                        </div>
                        <div className='terminos'>
                            <input type="checkbox" name ="terminos" id="terminos" value={true} onChange={handleTerminos}/>
                            <label for="terminos">Acepto los términos y condiciones</label>
                        </div>
                        {/* <div>
                            <button onClick={(e) => {
                                    handleSubmit(e)} }>
                                <input type="submit"/>
                            </button>
                        </div> */}
                    </form>    
                )}
            />
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Enviar!
            </button>
            <button id='atras-busquedas' onClick={(e) => {formularioEmpresa3()} }> Atras </button>
        </div>
        <div className='rectangulo'></div>
        <img src={wot23} alt='circulo' className='wot23' />
        <progress id="barra" max="100" value="100"> 100% </progress> 
        <label className='porcentaje'>100%</label>
    </div>
    )
}

export default Busqueda5;