import React, { useEffect, useState } from 'react';
import axios from 'axios';
import wot20 from '../../iconos/wot20.png';
import wot24 from '../../iconos/wot24.png';
import Header from '../../componentes/Header';

const Ponderadores = () => {
  const [relacionesIndustria, setRelacionesIndustria] = useState([]);
  const [updateMessage, setUpdateMessage] = useState('Puedes actualizar los valores de las ponderaciones.');
  const [showUpdateMessage, setShowUpdateMessage] = useState(true);
  const [industrias, setIndustrias] = useState([]);
  const jwtToken = localStorage.getItem('accessToken');
  
  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_industrias', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setRelacionesIndustria(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener relaciones de industrias', error);
      });
  }, [jwtToken]);

  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_industrias/industrias', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setIndustrias(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener industrias', error);
      });
  }, [jwtToken]);

  const relacionMatrix = {};
  relacionesIndustria.forEach(relacion => {
    const key = `${relacion.industriaA.nombre_industria}-${relacion.industriaB.nombre_industria}`;
    relacionMatrix[key] = relacion;
  });

  const handleActualizarClick = async (industriaA, industriaB, nuevaPonderacion) => {
    const relacionExistente = relacionesIndustria.find(rel => { 
      return (rel.industriaA.id === industriaA && rel.industriaB.id === industriaB) ||
              (rel.industriaA.id === industriaB && rel.industriaB.id === industriaA);
    });

    if (relacionExistente) {
      console.log("Relacion existe");
      axios
        .put(
          `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_industrias/${relacionExistente.id}`,
          { nuevaPonderacion },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const updatedRelacionesIndustria = relacionesIndustria.map((rel) => {
            if (rel.id === relacionExistente.id) {
              return { ...rel, ponderacion: nuevaPonderacion };
            }
            return rel;
          });
          setRelacionesIndustria(updatedRelacionesIndustria);
          setUpdateMessage('PONDERADOR ACTUALIZADO CORRECTAMENTE!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(true);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        })
        .catch((error) => {
          console.error('Error al actualizar ponderación', error);
          setUpdateMessage('Error al actualizar ponderador.');
        });
      } else {
        console.log("Relacion no existe");
      
        try {
          await axios.post(
            `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_industrias`,
            { id_industria_a: industriaA, id_industria_b: industriaB, nuevaPonderacion },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
      
          const response = await axios.get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_industrias', {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          });
      
          setRelacionesIndustria(response.data);
          setUpdateMessage('Nueva relación creada correctamente!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(false);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        } catch (error) {
          console.error('Error al crear nueva relación:', error);
          setUpdateMessage('Error al crear nueva relación.');
          setShowUpdateMessage(true);
        }
      };
  }

  return (
    <div className="WT1">
      <Header />  

      <div className="ponderadores-container">
        <p className="boldMiPerfil">Relaciones de Industrias</p>
        {showUpdateMessage && <p className='update-message'>{updateMessage}</p>}
        <table className="ponderadores-table">
          <thead>
            <tr>
              <th></th>
              {industrias.map((industria, index) => (
                <th key={index} className="header">{industria}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {industrias.map((industriaA, rowIndex) => (
              <tr key={rowIndex}>
                <th className="header">{industriaA}</th>
                {industrias.map((industriaB, colIndex) => (
                  <td key={colIndex}>
                    {rowIndex <= colIndex ? 
                      ((industriaA === industriaB) ? '1' : (
                        <input
                          type="number"
                          className="ponderacion-input"
                          value={(relacionMatrix[`${industriaA}-${industriaB}`] || {}).ponderacion || ''}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            if (!isNaN(newValue) && newValue >= 0 && newValue <= 1) {
                              const industriaAId = relacionesIndustria.find(rel => rel.industriaA.nombre_industria === industriaA).industriaA.id;
                              const industriaBId = relacionesIndustria.find(rel => rel.industriaB.nombre_industria === industriaB).industriaB.id;
                              handleActualizarClick(industriaAId, industriaBId, newValue);
                            }
                          }}
                          min="0"
                          max="1"
                          step="0.1"
                        />
                      ))
                      : ''}
                  </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="rectanguloEmpresas"></div>
  <img src={wot24} alt="circulo" className="wot24Empresas" />
</div>
);
};

export default Ponderadores;