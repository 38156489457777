import React, { useEffect, useState } from 'react';
import axios from 'axios';
import wot20 from '../../iconos/wot20.png';
import wot24 from '../../iconos/wot24.png';
import Header from '../../componentes/Header';

const Ponderadores = () => {
  const [relacionesArea, setRelacionesArea] = useState([]);
  const [updateMessage, setUpdateMessage] = useState('Puedes actualizar los valores de las ponderaciones.');
  const [showUpdateMessage, setShowUpdateMessage] = useState(true);
  const [areas, setAreas] = useState([]);
  const jwtToken = localStorage.getItem('accessToken');
  
  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_areas', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setRelacionesArea(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener relaciones de áreas', error);
      });
  }, [jwtToken]);

  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_areas/areas', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setAreas(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener áreas', error);
      });
  }, [jwtToken]);

  const relacionMatrix = {};
  relacionesArea.forEach(relacion => {
    const key = `${relacion.areaA.nombre}-${relacion.areaB.nombre}`;
    relacionMatrix[key] = relacion;
  });

  const handleActualizarClick = async (areaA, areaB, nuevaPonderacion) => {
    const relacionExistente = relacionesArea.find(rel => { 
      return (rel.areaA.id === areaA && rel.areaB.id === areaB) ||
              (rel.areaA.id === areaB && rel.areaB.id === areaA);
    });

    if (relacionExistente) {
      console.log("Relación existe");
      axios
        .put(
          `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_areas/${relacionExistente.id}`,
          { nuevaPonderacion },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const updatedRelacionesArea = relacionesArea.map((rel) => {
            if (rel.id === relacionExistente.id) {
              return { ...rel, ponderacion: nuevaPonderacion };
            }
            return rel;
          });
          setRelacionesArea(updatedRelacionesArea);
          setUpdateMessage('PONDERADOR ACTUALIZADO CORRECTAMENTE!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(true);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        })
        .catch((error) => {
          console.error('Error al actualizar ponderación', error);
          setUpdateMessage('Error al actualizar ponderador.');
        });
      } else {
        console.log("Relación no existe");
      
        try {
          await axios.post(
            `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_areas`,
            { id_area_a: areaA, id_area_b: areaB, nuevaPonderacion },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
      
          const response = await axios.get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_areas', {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          });
      
          setRelacionesArea(response.data);
          setUpdateMessage('Nueva relación creada correctamente!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(false);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        } catch (error) {
          console.error('Error al crear nueva relación:', error);
          setUpdateMessage('Error al crear nueva relación.');
          setShowUpdateMessage(true);
        }
      };
  }

  return (
    <div className="WT1">
      <Header />  
      <div className="ponderadores-container">
        <p className="boldMiPerfil">Relaciones de Áreas</p>
        {showUpdateMessage && <p className='update-message'>{updateMessage}</p>}
        <table className="ponderadores-table">
          <thead>
            <tr>
              <th></th>
              {areas.map((area, index) => (
                <th key={index} className="header">{area}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {areas.map((areaA, rowIndex) => (
              <tr key={rowIndex}>
                <th className="header">{areaA}</th>
                {areas.map((areaB, colIndex) => (
                  <td key={colIndex}>
                    {rowIndex <= colIndex ? 
                      ((areaA === areaB) ? '1' : (
                        <input
                          type="number"
                          className="ponderacion-input"
                          value={(relacionMatrix[`${areaA}-${areaB}`] || {}).ponderacion || ''}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            if (!isNaN(newValue) && newValue >= 0 && newValue <= 1) {
                              const areaAId = relacionesArea.find(rel => rel.areaA.nombre === areaA).areaA.id;
                              const areaBId = relacionesArea.find(rel => rel.areaB.nombre === areaB).areaB.id;
                              handleActualizarClick(areaAId, areaBId, newValue);
                            }
                          }}
                          min="0"
                          max="1"
                          step="0.1"
                        />
                      ))
                      : ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="rectanguloEmpresas"></div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default Ponderadores;