import '../empresas.css';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';


const LoginAdmin = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate()

    const handleLogin = (event) => {
        event.preventDefault();
        axios.post(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/auth/login-admin`, {
        email: username, 
        password: password
        })
        .then(response => {
            // Maneja la respuesta de inicio de sesión exitosa aquí.
            // Por ejemplo, podrías almacenar el token de acceso y el token de actualización en el almacenamiento local,
            // y luego redirigir al usuario a la página de perfil.
            //Almacena la información del usuario en el local storage
            localStorage.setItem('accessToken', response.data.token);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('user', JSON.stringify(response.data.user)); 
            console.log(response.data.user);
            navigate('/perfiladmin');
        })
        .catch(error => {
            // Maneja el error de inicio de sesión aquí.
            // Por ejemplo, podrías mostrar un mensaje de error al usuario.
            console.error(error);
            setErrorMessage(error.response.data.error);
        });
    };


    return (
    <div className="WT1">
        <header>
            <nav className='topnavbarempresas'>
                <img src={wot20} href='/' alt='logo wot'/>
                <a href='/'>Inicio WOT</a>
            </nav>
        </header>
        
        <div className='containerempresas'>
            <form onSubmit={handleLogin}>
            <div className='textoLogin-admin'>Login Administradoras</div> 
                <div className='nombreEmpresa'>
                    <label>Email </label>
                    <div></div>
                    <input
                        className='input1'
                        name="nombre"
                        type="text"
                        onChange={e => setUsername(e.target.value)}
                    />
                </div> 
                <div className='contrasena'>
                    <label>Contraseña</label>
                    <div></div>
                    <input
                        className='input1'
                        name="nombre"
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" id = "login">Login</button>
                {errorMessage && <div className='error-message'>{errorMessage}</div>}
                </form>
    
        <div className='olvidaste'><a href= "/">¿Olvidaste tu contraseña?</a></div>
        </div>
        <div className='rectangulo'></div>
        <img src={wot24} alt='circulo' className='wot24' />
    </div>
    )
}

export default LoginAdmin;