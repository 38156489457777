import React, { useEffect, useState } from 'react';
import axios from 'axios';
import wot20 from '../../iconos/wot20.png';
import wot24 from '../../iconos/wot24.png';
import Header from '../../componentes/Header';

const Ponderadores = () => {
  const [relacionesCompetencia, setRelacionesCompetencia] = useState([]);
  const [updateMessage, setUpdateMessage] = useState('Puedes actualizar los valores de las ponderaciones.');
  const [showUpdateMessage, setShowUpdateMessage] = useState(true);
  const [competencias, setCompetencias] = useState([]);
  const jwtToken = localStorage.getItem('accessToken');
  
  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_competencias', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setRelacionesCompetencia(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener relaciones de competencias', error);
      });
  }, [jwtToken]);

  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_competencias/competencias', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setCompetencias(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener competencias', error);
      });
  }, [jwtToken]);

  const relacionMatrix = {};
  relacionesCompetencia.forEach(relacion => {
    const key = `${relacion.competenciaA.competencia}-${relacion.competenciaB.competencia}`;
    relacionMatrix[key] = relacion;
  });

  const handleActualizarClick = async (competenciaA, competenciaB, nuevaPonderacion) => {
    const relacionExistente = relacionesCompetencia.find(rel => { 
      return (rel.competenciaA.id === competenciaA && rel.competenciaB.id === competenciaB) ||
              (rel.competenciaA.id === competenciaB && rel.competenciaB.id === competenciaA);
    });

    if (relacionExistente) {
      console.log("Relacion existe");
      axios
        .put(
          `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_competencias/${relacionExistente.id}`,
          { nuevaPonderacion },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const updatedRelacionesCompetencia = relacionesCompetencia.map((rel) => {
            if (rel.id === relacionExistente.id) {
              return { ...rel, ponderacion: nuevaPonderacion };
            }
            return rel;
          });
          setRelacionesCompetencia(updatedRelacionesCompetencia);
          setUpdateMessage('PONDERADOR ACTUALIZADO CORRECTAMENTE!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(true);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        })
        .catch((error) => {
          console.error('Error al actualizar ponderación', error);
          setUpdateMessage('Error al actualizar ponderador.');
        });
      } else {
        console.log("Relacion no existe");
      
        try {
          await axios.post(
            `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_competencias`,
            { id_competencia_a: competenciaA, id_competencia_b: competenciaB, nuevaPonderacion },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
      
          const response = await axios.get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_competencias', {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          });
      
          setRelacionesCompetencia(response.data);
          setUpdateMessage('Nueva relación creada correctamente!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(false);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        } catch (error) {
          console.error('Error al crear nueva relación:', error);
          setUpdateMessage('Error al crear nueva relación.');
          setShowUpdateMessage(true);
        }
      };
  }

  return (
    <div className="WT1">
      <Header />  
      <div className="ponderadores-container">
        <p className="boldMiPerfil">Relaciones de Competencias</p>
        {showUpdateMessage && <p className='update-message'>{updateMessage}</p>}
        <table className="ponderadores-table">
          <thead>
            <tr>
              <th></th>
              {competencias.map((competencia, index) => (
                <th key={index} className="header">{competencia}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {competencias.map((competenciaA, rowIndex) => (
              <tr key={rowIndex}>
                <th className="header">{competenciaA}</th>
                {competencias.map((competenciaB, colIndex) => (
                  <td key={colIndex}>
                    {rowIndex <= colIndex ? 
                      ((competenciaA === competenciaB) ? '1' : (
                        <input
                          type="number"
                          className="ponderacion-input"
                          value={(relacionMatrix[`${competenciaA}-${competenciaB}`] || {}).ponderacion || ''}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            if (!isNaN(newValue) && newValue >= 0 && newValue <= 1) {
                              const competenciaAId = relacionesCompetencia.find(rel => rel.competenciaA.competencia === competenciaA).competenciaA.id;
                              const competenciaBId = relacionesCompetencia.find(rel => rel.competenciaB.competencia === competenciaB).competenciaB.id;
                              handleActualizarClick(competenciaAId, competenciaBId, newValue);
                            }
                          }}
                          min="0"
                          max="1"
                          step="0.1"
                        />
                      ))
                      : ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="rectanguloEmpresas"></div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default Ponderadores;