import React from 'react';
import './formularioEmpresas.css';
import wot23 from '../iconos/wot24.png';
import { Form} from 'react-final-form';
import { useState } from "react";
import validator from 'validator'
import wot20 from '../iconos/wot20.png';
import ReactDOM from 'react-dom';
import Select, { components } from "react-select";
import FormularioEmpresa2 from './formularioEmpresa2';
import FormularioEmpresa4 from './formularioEmpresa4';


const FormularioEmpresa3 = () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root')
      );
    const [nombreContacto, setNombreContacto] = useState("");
    const [cargo, setCargo] = useState("");
    const [mail, setMail] = useState("");


    const handleNombreContacto = (e) => {
        setNombreContacto(e.target.value);
    };
    const handleCargo = (e) => {
        setCargo(e.target.value);
    };
    const handleMail = (e) => {
        setMail(e.target.value);
    };
    
    function formularioEmpresa2() {
        root.render(<FormularioEmpresa2/>);
        }
    function formularioEmpresa4() {
        root.render(<FormularioEmpresa4/>);
        }
    const handleSubmit= async(e) =>{
        if (nombreContacto.trim() === "" || cargo.trim() === "" || mail.trim() === "") {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else{
            localStorage.setItem("nombreContactoEmpresa", JSON.stringify(nombreContacto));
            localStorage.setItem("cargoEmpresa", JSON.stringify(cargo));
            localStorage.setItem("mailEmpresa", JSON.stringify(mail));
            root.render(<FormularioEmpresa4/>)
        }
    }
    return (
    <div className="WT1">
        <header>
        <nav className='topnavbar1'>
            <a href='/'  ><img src={wot20} alt='logo wot'/></a>
        </nav>
        </header>
        <label className='numero1-busquedas' >1</label>
        <label className='numero2-busquedas'>2</label>
        <label className='numero3-busquedas' id ='active'>3</label>
        <label className='busqueda-busquedas'>Perfil Empresa</label>
        <label className='perfilProfesional-busquedas' >Información Empresa</label>
        <label className='requisitos-busquedas' id = 'negrita-busquedas'>Usuario</label>
        <div className='container-busquedas'>
        <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='contacto-formularioEmpresa'>
                            <label>Nombre usuario de la empresa <span className='rojo'>*</span></label>
                            <div></div>
                            <input
                                className='input-contacto'
                                name="rut"
                                component="input"
                                type="text"
                                //value={prettifyRut(values.rut)}
                                onChange= {handleNombreContacto}
                            />
                        </div>
                        <div className='cargo-formularioEmpresa'>
                            <label>Cargo actual en la empresa <span className='rojo'>*</span></label>
                            <div></div>
                            <input
                                className='input-cargo'
                                name="rut"
                                component="input"
                                type="text"
                                //value={prettifyRut(values.rut)}
                                onChange= {handleCargo}
                            />
                       
                        </div>
                        <div className='mail-formularioEmpresa'>
                            <label>Mail de contacto <span className='rojo'>*</span></label>
                            <div></div>
                            <input
                                className='input-mail'
                                name="rut"
                                component="input"
                                type="text"
                                //value={prettifyRut(values.rut)}
                                onChange= {handleMail}
                            />
                        </div> 
                        
                        

                    </form>   
                )}
            />
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            <button id='atras-busquedas' onClick={(e) => {formularioEmpresa2()} }> Atras </button>
        </div>
        <div className='rectangulo'></div>
        <img src={wot23} alt='circulo' className='wot23' />
        <progress id="barra" max="100" value="75"> 75% </progress> 
        <label className='porcentaje'>75%</label>
    </div>
    )
}

export default FormularioEmpresa3;