import React, { useEffect, useState } from 'react';
import axios from 'axios';
import wot20 from '../../iconos/wot20.png';
import wot24 from '../../iconos/wot24.png';
import Header from '../../componentes/Header';

const Ponderadores = () => {
  const [ponderadores, setPonderadores] = useState([]);
  const [updateMessage, setUpdateMessage] = useState('Puedes actualizar los valores de las ponderaciones.');
  const [showUpdateMessage, setShowUpdateMessage] = useState(true);
  const jwtToken = localStorage.getItem('accessToken');

  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/ponderadores', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setPonderadores(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los ponderadores', error);
      });
  }, [jwtToken]);

  const handleActualizarClick = (relacionId, nuevaPonderacion) => {
    axios
      .put(
        `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/ponderadores/${relacionId}`,
        { nuevaPonderacion },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setUpdateMessage('PONDERADOR ACTUALIZADO CORRECTAMENTE!');
        setShowUpdateMessage(true);
        // Ocultar el mensaje después de 3 segundos (3000 milisegundos)
        setTimeout(() => {
          setShowUpdateMessage(false);
          setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
        }, 3000);
      })
      .catch((error) => {
        console.error('Error al actualizar ponderación', error);
        setUpdateMessage('Error al actualizar ponderador.');
      });
  };


  const handlePonderacionChange = (index, newValue) => {
    const newPonderadores = [...ponderadores];
    const sumOfPonderadoresExcludingCurrent = newPonderadores.reduce((sum, item, i) => {
      if (i !== index) {
        return sum + item.ponderacion;
      }
      return sum;
    }, 0);
  
    // Si la suma de las ponderaciones (excluyendo el valor anterior) más el nuevo valor es mayor que 1, muestra un mensaje de error
    if (sumOfPonderadoresExcludingCurrent + newValue > 1) {
      setUpdateMessage('La suma de los ponderadores no puede ser mayor a 1.');
      setShowUpdateMessage(true);
      return;
    }
  
    // Actualizar el valor del ponderador solo si es menor o igual a 1
    if (newValue <= 1) {
      newPonderadores[index].ponderacion = newValue;
      setPonderadores(newPonderadores);
      handleActualizarClick(newPonderadores[index].id, newValue);
    } else {
      setUpdateMessage('El valor del ponderador no puede ser mayor que 1.');
      setShowUpdateMessage(true);
    }
  };
  
  const funcionActual = ponderadores.map(relacion => `${relacion.atributo} * ${relacion.ponderacion}`).join(' + ');
  

  // Función para calcular el valor de la función objetivo
  const calcularFuncionObjetivo = () => {
    const resultado = ponderadores.reduce((total, item) => {
      return total + (item.ponderacion);
    }, 0);
    return resultado;
  };

  

  return (
    <div className="WT1">
      <Header />  

      <div className="ponderadores-container">
        <p className="boldMiPerfil">Ponderadores</p>
        <p> Función actual: </p>
        <p className='funcion-actual'> {funcionActual}</p>
        
        {showUpdateMessage && <p className='update-message'>{updateMessage}</p>}

        <table className="ponderadores-table">
          <thead>
            <tr>
              <th>Ponderación</th>
              <th>Atributo</th>
            </tr>
          </thead>
          <tbody>
            {ponderadores.map((relacion, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="number"
                    value={relacion.ponderacion}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      if (!isNaN(newValue) && newValue >= 0 && newValue <= 1) {
                        handlePonderacionChange(index, newValue);
                      }
                    }}
                    min="0"
                    max="1"
                    step="0.01"
                  />
                </td>
                <td>{relacion.atributo}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="2">SUMA PONDERADORES: {calcularFuncionObjetivo()}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="rectanguloEmpresas"></div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default Ponderadores;