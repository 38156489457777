import React from 'react';
import './formularioEmpresas.css';
import wot23 from '../iconos/wot24.png';
import { Form} from 'react-final-form';
import { useState, useEffect } from "react";
import validator from 'validator'
import wot20 from '../iconos/wot20.png';
import ReactDOM from 'react-dom';
import Select, { components } from "react-select";
import FormularioEmpresa from './formularioEmpresa';
import FormularioEmpresa2 from './formularioEmpresa2';


const FormularioEmpresa1 = () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root')
      );
    const [industrias, setIndustrias] = useState([]); //array de industrias
    const [regiones, setRegiones] = useState([]); //array de regiones
    const [anosFundacion, setAnosFundacion] = useState([]); //array de años de fundacion
    const [anoFundacion, setAnoFundacion] = useState("");
    const [region, setRegion] = useState("");
    const [industria, setIndustria] = useState("");
    const [rut, setRut] = useState("");
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/industrias`)
        .then(response => response.json())
        .then(data => {
            const lista = data.map((index) => {
                return {value: index.id , label: index.nombre_industria}
            })
            setIndustrias(lista)
        }
        )
        .catch(error => console.log(error));
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/regiones`)
        .then(response => response.json())
        .then(data => {
            const regiones = data.map((index) => {
                return {value: index.id , label:index.nombre}
            })
            setRegiones(regiones)
        })
        .catch(error => console.log(error));
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/anosFundacion`)
        .then(response => response.json())
        .then(data => {
            const anosFundacion = data.map((index) => {
                return {value: index.id , label: (index.rango_anios_desde + "-" + index.rango_anios_hasta)}
            })
            setAnosFundacion(anosFundacion)
        })
        .catch(error => console.log(error));
    }, []);

    const handleRut = (e) => {
        setRut(e.target.value);
    };
    const handleNombre = (e) => {
        setNombre(e.target.value);
    };
    const handleRegion = (e) => {
        setRegion(e.target.value);
    };
    const handleIndustria = (e) => {
        setIndustria(e.target.value);
    };
    const handleAnoFundacion = (e) => {
        setAnoFundacion(e.target.value);
    };
    function formularioEmpresa() {
        root.render(<FormularioEmpresa/>);
        }
    function formularioEmpresa2() {
        root.render(<FormularioEmpresa2/>);
        }
    const handleSubmit= async(e) =>{
        if (rut.trim() === "" || nombre.trim() === "" || industria.trim() === ""|| region.trim() === "" || anoFundacion.trim() === "" ) {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else{
            localStorage.setItem("rutEmpresa", JSON.stringify(rut));
            localStorage.setItem("nombreEmpresa", JSON.stringify(nombre));
            localStorage.setItem("industriaEmpresa", JSON.stringify(industria));
            localStorage.setItem("regionEmpresa", JSON.stringify(region));
            localStorage.setItem("anoFundacionEmpresa", JSON.stringify(anoFundacion));
            root.render(<FormularioEmpresa2/>)
        }
    }
    return (
    <div className="WT1">
        <header>
        <nav className='topnavbar1'>
            <a href='/'  ><img src={wot20} alt='logo wot'/></a>
        </nav>
        </header>
        <label className='numero1-busquedas' id ='active'>1</label>
        <label className='numero2-busquedas'>2</label>
        <label className='numero3-busquedas'>3</label>
        <label className='busqueda-busquedas' id = 'negrita-busquedas'>Perfil Empresa</label>
        <label className='perfilProfesional-busquedas'>Información Empresa</label>
        <label className='requisitos-busquedas'>Usuario</label>
        <div className='container-busquedas'>
        <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='rut-formularioEmpresa'>
                            <label>Rut <span className='rojo'>*</span></label>
                            <div></div>
                            <input
                                className='input-rut'
                                name="rut"
                                component="input"
                                type="text"
                                placeholder='Ej: 23245245-K'
                                //value={prettifyRut(values.rut)}
                                onChange= {handleRut}
                            />
                        </div>
                        <div className='nombre-formularioEmpresa'>
                            <label>Nombre de la empresa <span className='rojo'>*</span></label>
                            <div></div>
                            <input
                                className='input-nombreEmpresa'
                                name="nombre"
                                component="input"
                                type="text"
                                //value={prettifyRut(values.rut)}
                                onChange= {handleNombre}
                            />
                        </div>
                        <div className='industria-formularioEmpresa'>
                            <label>Industria a la cuál pertenece la empresa<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-industria' onChange={handleIndustria}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                {industrias.map((industria) => (
                                    <option value={industria.value}>{industria.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='region-formularioEmpresa'>
                            <label>Región en la que se encuentra la casa matriz de la empresa<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-regionEmpresa' onChange={handleRegion}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                {regiones.map((region) => (
                                    <option value={region.value}>{region.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='anoFundacion-formularioEmpresa'>
                            <label>Años desde la fundación de la empresa<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-anoFundacion' onChange={handleAnoFundacion}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                {anosFundacion.map((anoFundacion) => (
                                    <option value={anoFundacion.value}>{anoFundacion.label}</option>
                                ))}
                            </select>
                        </div>

                    </form>   
                )}
            />
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            <button id='atras-busquedas' onClick={(e) => {formularioEmpresa()} }> Atras </button>
        </div>
        <div className='rectangulo'></div>
        <img src={wot23} alt='circulo' className='wot23' />
        <progress id="barra" max="100" value="25"> 25% </progress> 
        <label className='porcentaje'>25%</label>
    </div>
    )
}

export default FormularioEmpresa1;
