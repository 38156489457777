import React from 'react';
import './formularioEmpresas.css';
import wot23 from '../iconos/wot24.png';
import { Form} from 'react-final-form';
import { useState, useEffect } from "react";
import validator from 'validator'
import wot20 from '../iconos/wot20.png';
import ReactDOM from 'react-dom';
import wot_inicio from '../img/brochure wot final iniccio.png';
import Select, { components } from "react-select";
import FormularioEmpresa1 from './formularioEmpresa1';
import FormularioEmpresa3 from './formularioEmpresa3';


const FormularioEmpresa2 = () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root')
      );
    const [ingresos, setIngresos] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    //const ingresos = [{value: 1, label: '< 25.000 UF'}, {value: 2, label:'25.000 - 100.000 UF'},{value: 3, label:'> 100.000 UF'}]
    const [ingreso, setIngreso] = useState(""); 
    //const empleados = [{value: 1, label:'<10'}, {value: 2, label:'10-49'},{value: 3, label:'50-199'},{value: 4, label:'> 200'}];
    const [empleado, setEmpleado] = useState("");
    const [valores, setValores] = useState('');
    const [esg, setEsg] = useState(null);
    const [indicadores, setIndicadores] = useState(null);
    const [empresaB, setEmpresaB] = useState(null);

    useEffect(() => {
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/ingresosAnuales`)
        .then(response => response.json())
        .then(data => {
            const ingresos = data.map((index) => {
                return {value: index.id , label: (index.rango_ingresos_desde + "-" + index.rango_ingresos_hasta)}
            })
            setIngresos(ingresos)
        })
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/cantidadEmpleados`)
        .then(response => response.json())
        .then(data => {
            const empleados = data.map((index) => {
                return {value: index.id , label: (index.rango_empleados_desde + "-" + index.rango_empleados_hasta)}
            })
            setEmpleados(empleados)
        })
    }, []);


    const handleIngreso = (e) => {
        setIngreso(e.target.value);
    };
    const handleEmpleado = (e) => {
        setEmpleado(e.target.value);
    };
    const handleValores = (e) => {
        setValores(e.target.value);
    };
    const handleEsg = (e) => {
        setEsg(e.target.value);
    };
    const handleIndicadores = (e) => {
        setIndicadores(e.target.value);
    };
    const handleEmpresaB = (e) => {
        setEmpresaB(e.target.value);
    };
    function formularioEmpresa1() {
        root.render(<FormularioEmpresa1/>);
        }
    function formularioEmpresa3() {
        root.render(<FormularioEmpresa3/>);
        }
    const handleSubmit= async(e) =>{
        if (ingreso.trim() === "" || empleado.trim() === "" || valores.trim() === ""|| esg.trim() === "" || indicadores.trim() === "" || empresaB.trim() === "") {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else{
            localStorage.setItem("ingresoEmpresa", JSON.stringify(ingreso));
            localStorage.setItem("empleadoEmpresa", JSON.stringify(empleado));
            localStorage.setItem("valoresEmpresa", JSON.stringify(valores));
            localStorage.setItem("esgEmpresa", JSON.stringify(esg));
            localStorage.setItem("indicadoresEmpresa", JSON.stringify(indicadores));
            localStorage.setItem("empresaB", JSON.stringify(empresaB));
            root.render(<FormularioEmpresa3/>)
        }
    }
    return (
    <div className="WT1">
        <header>
        <nav className='topnavbar1'>
            <a href='/'  ><img src={wot20} alt='logo wot'/></a>
        </nav>
        </header>
        <label className='numero1-busquedas' >1</label>
        <label className='numero2-busquedas'id ='active'>2</label>
        <label className='numero3-busquedas'>3</label>
        <label className='busqueda-busquedas'>Perfil Empresa</label>
        <label className='perfilProfesional-busquedas' id = 'negrita-busquedas'>Información Empresa</label>
        <label className='requisitos-busquedas'>Usuario</label>
        <div className='container-busquedas'>
        <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='ingresos-formularioEmpresa'>
                            <label>Ingresos anuales<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-ingresos' onChange={handleIngreso}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                {ingresos.map((ingreso) => (
                                    <option value={ingreso.value}>{ingreso.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='empleados-formularioEmpresa'>
                            <label>Número aproximado de empleados<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-empleados' onChange={handleEmpleado}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                {empleados.map((empleado) => (
                                    <option value={empleado.value}>{empleado.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='valores-formularioEmpresa'>
                            <label>Cuáles son los principales valores de la empresa <span className='rojo'>*</span></label>
                            <div></div>
                            <label className='ejemplo'>Máximo 5 palabras</label>
                            <div></div>
                            <input
                                className='input-valores'
                                name="rut"
                                component="input"
                                type="text"
                                placeholder='Ej: 23245245-K'
                                //value={prettifyRut(values.rut)}
                                onChange= {handleValores}
                            />
                        </div>
                        <div className='esg-formularioEmpresa'>
                            <label>La empresa cuenta con políticas ESG<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-esg' onChange={handleEsg}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='impacto-formularioEmpresa'>
                            <label>La empresa cuenta con indicadores de impacto<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-impacto' onChange={handleIndicadores}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='empresaB-formularioEmpresa'>
                            <label>Es una empresa B<span className='rojo'>*</span></label>
                            <div></div>
                            <select className='input-empresaB' onChange={handleEmpresaB}>
                                <option value="" disabled selected>Seleccione una opción</option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>

                    </form>   
                )}
            />
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            <button id='atras-busquedas' onClick={(e) => {formularioEmpresa1()} }> Atras </button>
        </div>
        <div className='rectangulo'></div>
        <img src={wot23} alt='circulo' className='wot23' />
        <progress id="barra" max="100" value="50"> 50% </progress> 
        <label className='porcentaje'>50%</label>
    </div>
    )
}

export default FormularioEmpresa2;