import React from 'react';
import wot20 from '../iconos/wot20.png';

const Header = () => {
  return (
    <header>
      <nav className="topnavbarempresas">
        <img src={wot20} href="/" alt="logo wot" />
        <a href="/">Inicio</a>
        <a href="/perfilAdmin">Perfil</a>
        <a href="/desbloqueados">Desbloqueados</a>
        <a href="/form-empresas">Inscripción Empresa</a>
        <div className="submenu">
          <a href="#">Usuarias ▼</a>
          <div className="submenu-content">
            <a href="/usuariasAdmin">Lista Usuarias</a>
            <a href="/cambioContrasena">Cambio Contraseña</a>
          </div>
        </div>
        <div className="submenu">
          <a href="#">Usuarios Empresa ▼</a>
          <div className="submenu-content">
            <a href="/agregarUsuario">Agregar Usuario</a>
            <a href="/eliminarUsuario">Eliminar Usuario</a>
          </div>
        </div>
        <div className="submenu">
          <a href="#">Relaciones ▼</a>
          <div className="submenu-content">
            <a href="/relaciones_competencias">Relaciones Competencias</a>
            <a href="/relaciones_areas">Relaciones Areas</a>
            <a href="/relaciones_industrias">Relaciones Industrias</a>
            <a href="/relaciones_profesiones">Relaciones Profesiones</a>
            <a href="/ponderadores">Ponderadores</a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
