import React from 'react';
import './formularioEmpresas.css';
import wot23 from '../iconos/wot24.png';
import { Form} from 'react-final-form';
import { useState } from "react";
import validator from 'validator'
import wot20 from '../iconos/wot20.png';
import ReactDOM from 'react-dom';
import wot_inicio from '../img/brochure wot final iniccio.png';
import Select, { components } from "react-select";



const Busqueda6 = () => {
    return (
        <div className="WT1">
            <header>
            <nav className='topnavbar1'>
                <a href='/'  ><img src={wot20} alt='logo wot'/></a>
            </nav>
            </header>
            <div className='container'>
                <label className='texto1'>¡Formulario enviado!</label> 
                <div></div>
                <img src={wot_inicio} className="WT-logo2" alt="primera foto" />
                <div></div>
                <label className='texto5'>El formulario ha sido enviado.</label>
                <div></div>
                <label className='texto6'>Bienvenidos a la comunidad Wot.</label>
                <div></div>
                
            </div>
            <div className='rectangulo'></div>
            <img src={wot23} alt='circulo' className='wot23' />
            <progress id="barra" max="100" value="100"> 100% </progress> 
            <label className='porcentaje'>100%</label>
        </div>
        )
        
    }
    

export default Busqueda6;