import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FormularioEmpresa from './formulario/formularioEmpresa';
import LoginAdmin from './admin/login-admin';
import PerfilAdmin from './admin/perfil-admin';
import Empresa from './admin/empresa';
import UsuariasAdmin from './admin/usuarias';
import DetalleUsuarias from './admin/detalleUsuaria';
import Busquedas from './admin/busquedas';
import Matchs from './admin/matchs';
import RelacionesProfesiones from './admin/ponderadores/relaciones_profesiones';
import Ponderadores from './admin/ponderadores/ponderadores';
import RelacionesIndustrias from './admin/ponderadores/relaciones_industrias';
import RelacionAreas from './admin/ponderadores/relaciones_areas';
import RelacionCompetencias from './admin/ponderadores/relaciones_competencias';
import AgregarUsuario from './admin/agregarUsuario';
import EliminarUsuario from './admin/eliminarUsuario';
import Desbloqueados from './admin/desbloqueados';
import CambioContrasena from './admin/cambioContrasena';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginAdmin />} />
        <Route path="/form-empresas" element={<FormularioEmpresa/>} />
        <Route path="/loginAdmin" element={<LoginAdmin/>} />
        <Route path="/perfilAdmin" element={<PerfilAdmin/>} />
        <Route path="/empresa/:id" element={<Empresa/>} />
        <Route path="/usuariasAdmin" element={<UsuariasAdmin/>} />
        <Route path="/detalleUsuaria/:id" element={<DetalleUsuarias/>} />
        <Route path="/busquedas-empresa/:id" element={<Busquedas/>} />
        <Route path="/matchs-empresa/:id" element={<Matchs/>} />
        <Route path="/relaciones_profesiones" element={<RelacionesProfesiones/>} />
        <Route path="/ponderadores" element={<Ponderadores/>} />
        <Route path="/relaciones_industrias" element={<RelacionesIndustrias/>} />
        <Route path="/relaciones_areas" element={<RelacionAreas/>} />
        <Route path="/relaciones_competencias" element={<RelacionCompetencias/>} />
        <Route path="/agregarUsuario" element={<AgregarUsuario/>} />
        <Route path="/eliminarUsuario" element={<EliminarUsuario/>} />
        <Route path="/desbloqueados" element={<Desbloqueados/>} />
        <Route path="/cambioContrasena" element={<CambioContrasena/>} />

      </Routes>
    </BrowserRouter>
);

reportWebVitals();