import React, { useEffect, useState } from 'react';
import axios from 'axios';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import '../empresas.css';
import './admin.css';
import Header from '../componentes/Header.js';

const DetallesResultadoDesbloqueado = ({ busqueda, usuaria, volverAtras }) => {

  return (
    <div className="containerMiPerfil2">
      <h2>Detalles de la Búsqueda</h2>
      <p>Nombre de la búsqueda: {busqueda?.nombre || 'No especificado'}</p>
      <p>Empresa de la búsqueda: {busqueda?.empresa?.nombre || 'No especificado'}</p>
      <p>Cargo de la búsqueda: {busqueda?.cargo?.nombre || 'No especificado'}</p>
      <p>Jornada de la búsqueda: {busqueda?.jornada?.nombre || 'No especificado'}</p>
      <p>Región de la búsqueda: {busqueda?.region?.nombre || 'No especificado'}</p>
      <p>Modalidad de la búsqueda: {busqueda?.modalidad?.nombre || 'No especificado'}</p>
      <p>Profesiones de la búsqueda: {busqueda?.profesiones?.map(profesion => profesion.nombre).join(', ') || 'No especificado'}</p>
      <p>Competencias de la búsqueda: {busqueda?.competencias?.map(competencia => competencia.nombre).join(', ') || 'No especificado'}</p>
      <p>Industrias de la búsqueda: {busqueda?.industrias?.map(industria => industria.nombre).join(', ') || 'No especificado'}</p>
      <p>Idiomas de la búsqueda: {busqueda?.idiomas?.map(idioma => idioma.nombre).join(', ') || 'No especificado'}</p>
      <p>Áreas de la búsqueda: {busqueda?.areas?.map(area => area.nombre).join(', ') || 'No especificado'}</p>

      <h2>Detalles de la Usuaria</h2>
      <p>Nombre de la usuaria: {usuaria?.nombre || 'No especificado'}</p>
      <p>Apellido de la usuaria: {usuaria?.apellido || 'No especificado'}</p>
      <p>Rut de la usuaria: {usuaria?.rut || 'No especificado'}</p>
      <p>Número de celular de la usuaria: {usuaria?.celular || 'No especificado'}</p>
      <p>Correo electrónico de la usuaria: {usuaria?.mail || 'No especificado'}</p>
      <p>Universidad de la usuaria: {usuaria?.universidad || 'No especificado'}</p>
      <p>Postgrado de la usuaria: {usuaria?.postgrado || 'No especificado'}</p>
      <p>Empresa actual de la usuaria: {usuaria?.empresa_actual || 'No especificado'}</p>

      <button onClick={volverAtras}>Volver atrás</button>
    </div>
  );
};

const ResultadoDesbloqueadoItem = ({ resultadoDesbloqueado, handleVerDetalle }) => {
  console.log("resultadoDesbloqueado", resultadoDesbloqueado)
  const jwtToken = localStorage.getItem('accessToken');
  const id_match = resultadoDesbloqueado.id_match;
  const [matchs, setMatchs] = useState([]);
  const [usuaria, setUsuaria] = useState(null);
  const [busqueda, setBusqueda] = useState(null);

  

  useEffect(() => {
    axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/match/${id_match}`, {
      headers: {
        authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setMatchs(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id_match, jwtToken]);



  useEffect(() => {
      const idUsuaria = matchs.id_usuaria;

      axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/usuaria/${idUsuaria}`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          // Actualizar el estado con la información de la usuaria
          setUsuaria(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    
  }, [jwtToken]);

  const id_usuaria = matchs.id_usuaria;
  const fechaMatch = resultadoDesbloqueado.createdAt
    ? new Date(resultadoDesbloqueado.createdAt).toLocaleDateString('es-CL')
    : 'No especificado';

  return (
    <tr>
      <td>{resultadoDesbloqueado.busqueda.id_empresa}</td>
      <td>{resultadoDesbloqueado.busqueda.nombre}</td>
      <td>{fechaMatch}</td>
      <td>
        <button onClick={() => handleVerDetalle(resultadoDesbloqueado.busqueda.id, id_usuaria)}>Ver Detalle</button>
      </td>
    </tr>
  );
};

const Desbloqueados = () => {
  const [resultadosDesbloqueados, setResultadosDesbloqueados] = useState([]);
  const [detalleVisible, setDetalleVisible] = useState(false);
  const [busqueda, setBusqueda] = useState(null);
  const [usuaria, setUsuaria] = useState([]);
  const [detalleResultado, setDetalleResultado] = useState(null);
  const jwtToken = localStorage.getItem('accessToken');

  //busco todos los resultado desbloqueados 

  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/desbloqueados', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setResultadosDesbloqueados(response.data.reverse());
      })
      .catch((error) => {
        console.error('Error al obtener resultados desbloqueados', error);
      });
  }, [jwtToken]);


  const handleVerDetalle = async (id_busqueda, usuariaId) => {
    try {
      // Obtener información de la búsqueda
      const responseBusqueda = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/${id_busqueda}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Obtener información de la usuaria
      const responseUsuaria = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/usuaria/${usuariaId}`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Establecer estados
      setBusqueda(responseBusqueda.data);
      setUsuaria(responseUsuaria.data);
      setDetalleResultado(resultadosDesbloqueados.find((resultado) => resultado.busqueda.id === id_busqueda));
      setDetalleVisible(true);
    } catch (error) {
      console.error('Error al obtener información de la búsqueda', error);
    }
  };
  
  const handleVolverAtras = () => {
    setDetalleVisible(false);
    setDetalleResultado(null);
  };

  return (
    <div className="WT1">
      <Header />

      <div className="containerMiPerfil1">
        <p className="boldMiPerfil">Resultados Desbloqueados</p>
        <p className="normalMiPerfil">Acá se pueden ver todos los resultados desbloqueados.</p>
      </div>

      {detalleVisible ? (
        <DetallesResultadoDesbloqueado
          busqueda={busqueda}
          usuaria={usuaria}
          volverAtras={handleVolverAtras}
        />
      ) : (
        <div className="containerMiPerfil2">
          <table className="desbloqueados-table">
            <thead>
              <tr>
                <th>ID Empresa</th>
                <th>Nombre de la Búsqueda</th>
                <th>Fecha del Match</th>
                <th>Ver Detalle</th>
              </tr>
            </thead>
            <tbody>
              {resultadosDesbloqueados.map((resultado, index) => (
                <ResultadoDesbloqueadoItem key={index} resultadoDesbloqueado={resultado} handleVerDetalle={handleVerDetalle} />
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="rectanguloEmpresas"></div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default Desbloqueados;
