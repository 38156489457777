import '../empresas.css';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Header from '../componentes/Header';


const CambioContrasena = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate()
    const jwtToken = localStorage.getItem('accessToken');
    const [nombre, setNombre] = useState('');
    const [email , setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [username, setUsername] = useState('');

    const obtenerId = (newEmail) => {
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/users/user-by-mail/${newEmail}`, {
            headers: {
                Authorization: `Bearer ${jwtToken}`
            }
        })
        .then(response => {
            console.log(response.data.id)
            setUsername(response.data.id); // ver si es con data o sin data
        }
    )
    .catch(error => {
        console.error(error);
        // Asegurarse de que existe error.response y error.response.data antes de acceder a error.response.data.error
        // setErrorMessage(error.response && error.response.data ? error.response.data.error : "An error occurred");
    }
    );
    }

    const handleChange = async (event) => {
        event.preventDefault();  // Only necessary if you're dealing with form submissions.
        
        const newEmail = event.target.value;
        setEmail(newEmail);  // This updates the state asynchronously.
        
        console.log(newEmail);  // Log the new value from the event.
        
        await obtenerId(newEmail);  // Make sure this function doesn't depend on `email` directly, since setEmail is async.
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        axios.post(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/users/change-password`, 
        {username: username, newPassword: newPassword}, 
        {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            navigate('/perfiladmin');
        })
        .catch(error => {
            console.error(error);
            // Asegurarse de que existe error.response y error.response.data antes de acceder a error.response.data.error
            setErrorMessage(error.response && error.response.data ? error.response.data.error : "An error occurred");
        });
    };


    return (
    <div className="WT1">
        <Header /> 
        <div className='containerempresas'>
            <form onSubmit={handleSubmit}>
            <div className='texto-admin'>Cambio de contraseña para una usuaria</div>  
                <div className='nombreUsuario'>
                    <label> Email </label>
                    <div></div>
                    <input
                        className='input1'
                        name="email"
                        type="text"
                        onChange={handleChange}
                    />
                </div> 
                <div className='nuevaContrasena'>
                    <label> Nueva Contraseña </label>
                    <div></div>
                    <input
                        className='input1'
                        name="newPassword"
                        type="text"
                        onChange={e => setNewPassword(e.target.value)}
                    />
                </div> 
                <button type="submit" id = "enviar">Enviar</button>
                {errorMessage && <div className='error-message'>{errorMessage}</div>}
                </form>
   
        </div>
        <div className='rectangulo'></div>
        <img src={wot24} alt='circulo' className='wot24' />
    </div>
    )
}

export default CambioContrasena;