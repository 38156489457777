import '../empresas.css';
import './admin.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header.js';

const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}

const Usuarias = () => {
    const [busquedas, setBusquedas] = useState([]);
    const [error, setError] = useState(null);
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/empresa/${id}`, {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const data = response.data;
            if (Array.isArray(data)) {
                console.log(data);
                setBusquedas(data);
            } else if (typeof data === 'object') {
                setBusquedas([data]);
            }
        })
        .catch(error => console.error('Error:', error));
    }, []);
    console.log(busquedas)
    const handleVerDetalle = (id) => {
        navigate(`/matchs-empresa/${id}`);
    }
    return (
        <div className="WT1">
        <Header />
        <div className='textoBusuqeda'> Búsquedas </div> 
        <div className='containerempresas'>
            {/* lista de las busquedas con un boton de ver detalle */}
            {busquedas.map((busqueda) => (
                <div className='cont-busqueda'>
                    <div className='nombre-busqueda'>{busqueda.nombre}</div>
                    <button onClick={() => handleVerDetalle(busqueda.id)} className='boton-busquedas'>Ver Detalle</button>
                </div>
            ))}
        </div>
        <div className='rectangulo'></div>
        <img src={wot24} alt='circulo' className='wot24' />
    </div>
    );  
}

export default Usuarias