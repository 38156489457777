// import { CircularProgressbar } from 'react-circular-progressbar';
import './App.css';
import img1 from './img/gris female-executive-manager-and-team.jpg';
import fotoSilvia from './img/Silvia Baeza 2.jpg';
import fotoCata from './img/cata.png';
import fotoCarolina from './img/carolina.jpg'; 
import fotoFca from './img/fca.jpg'; 
import styled from "styled-components";
import foto1 from './img/rldYLP copia.jpg';
import foto2 from './img/lbzD8n copia.jpg';
import foto3 from './img/ize3Ue copia.jpg';
import foto4 from './img/tVOpiq copia.jpg';
import preferirnos from './img/smiling-blonde-business-woman-pointing-away-over-white.jpg'
import mujeres from './img/2young-female-executive-explaines-new-strategy-to-blonde-employee-in-glasses-and-smiling-indoor-portrait-of-multicultural-collective-working-on-project-in-office-and-using-laptop.jpg'
import mujerCelular from './img/portrait-of-happy-woman-using-mobile-phone.jpg'
import grupoPersonas from './img/group-of-business-executives-using-laptop-at-their-desk.jpg'
import wot1 from './iconos/wot1.png';
// import wot2 from './iconos/wot2.png';
// import wot3 from './iconos/wot3.png';
import wot4 from './iconos/wot4.png';
import wot5 from './iconos/wot5.png';
import wot6 from './iconos/wot6.png';
// import wot7 from './iconos/wot7.png';
import wot8 from './iconos/wot8.png';
import wot9 from './iconos/wot9.png';
import wot10 from './iconos/wot10.jpg';
import wot11 from './iconos/wot11.png';
import wot13 from './iconos/wot13.png';
import wot14 from './iconos/wot14.png';
import wot15 from './iconos/wot15.png';
// import wot16 from './iconos/wot16.png';
// import wot17 from './iconos/wot17.png';
// import wot18 from './iconos/wot18.png';
// import wot19 from './iconos/wot19.png';
import wot20 from './iconos/wot20.png';
import wot21 from'./iconos/wot21.png';
import click from'./iconos/clic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import wot_inicio from './img/brochure wot final iniccio.png'
import mujeres_empresa from './img/brochure wot final sello empres.png'

function myFunction() {
  var x = document.getElementById("myLinks");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}
function App() {
  // const porcentaje = 12; 
  // const porcentaje2 = 21;
  
  return (
    <div className="WT">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <header>
      <nav className='topnavbar'>
        <img src={wot20} href='/' alt='logo wot'/>
        {/* <a href = '/formusuarias' >Inscripción</a>
        <a href='/form-empresas' >Inscripción Empresa</a> */}
        <a href='/formusuarias'>Inscripción Usuarias</a>
        <a href='/login' className='no'>Login Empresas</a>
      </nav>
      </header>
      <div >
        <img src={wot_inicio} className='WT-logo' alt='woman talent' />
        {/* <img src={img1} className="WT-logo" alt="primera foto" />
        <div className='cuadrado-fondo'></div>
        <img src={wot1} className='wot1' alt='logo wot'/> */}
        {/* <div className='vlc6'></div> */}
        {/* <label className='hashtag1'> #somoswot</label>
        <label className='hashtag2'>#soywot</label> */}
        <a name='nuestroproposito'></a>
      </div>
      
      <div className='Nuestro-proposito'>
        
        <div className='vlc'></div>
        <img src={wot5} className='wot5' alt='cara feliz'></img>
        <p className='mujeres3'>NUESTRO PROPÓSITO</p>
        <CuadradoTexto5><span className='rosa'>Sumar mujeres en posiciones de liderazgo</span>, conectando de manera simple y directa, talento femenino con empresas e instituciones.</CuadradoTexto5>
        <img src={wot6} className='wot6' alt='progressbar'></img>
        <label className='circulo1'>12% participación de mujeres en dirección</label>
        <label className='circulo2'>21% participación de mujeres en gerencia</label>
        {/* <div></div>
        <CircularProgressbar className='circulo1' value={porcentaje} text='12% participacion de mujeres en dirección'/>
        <div></div>
        <CircularProgressbar className='circulo2' value={porcentaje2} text='21% de participación de mujeres en gerencia'/>
        <div></div> */}
        <label className='circulo3'>Porcentaje de la baja representación femenina en alta dirección.</label>
        <a name='proyecto'></a>
        <img src={grupoPersonas} className='GrupoPersonas' alt="grupo personas" />
        
      </div>

      <div>
        <div className='vlc7'></div>
        <img src={mujerCelular} className="mujerCelular" alt= "mujer con el  celular" />
        <img src={wot8} className='wot8' alt='logo proyecto'/>
        <p className='mujeres2'>PROYECTO</p>
        <CuadradoTexto4>Plataforma digital que <span className='negrita'>conecte a empresas e instituciones, con mujeres profesionales que puedan aportar su capital humano y talento</span>, en cargos de alta responsabilidad , directorios, seminarios , estrategia, etc. </CuadradoTexto4>
        <img src={wot9} className='wot9' alt='union oficina con personas'></img>
        <img src={wot10} className='wot10' alt='logos proyecto'></img>
        <label className='hashtag3'> #somoswot</label>
        <label className='hashtag4'>#soywot</label>
        <a name='comoopera'></a>
      </div>
      <div>
        <div className='vlc2'></div>
        <img src={mujeres} alt= 'mujeres' className='Foto-mujeres'/>
        <img src={wot11} alt='puzle' className='wot11'></img>
        <p className='mujeres1'> CÓMO OPERA PARA MUJERES </p>
        <CuadradoTexto6>Al <span className='negrita'>llenar su formulario con su experiencia, sus habilidades e intereses</span> y aceptar los términos y condiciones del uso de la plataforma, la <span className='negrita'>profesional comienza a ser parte de Wot</span> y queda lista para comenzar a hacer match.</CuadradoTexto6>
        <label className='hashtag5'>#soywot</label>
      </div>
      <div>
        <a name='pqpreferirnos'></a>
        <img src={mujeres_empresa} alt= 'mujeres_empresa' className='mujeres_empresa'/>
        <img src={wot11} alt='puzle' className='wot11_1'></img>
        <p className='mujeres1_1'> CÓMO OPERA PARA EMPRESAS</p>
        <CuadradoTexto61> Mediante un <span className='negrita'>Fee Anual, la empresa o institución pasa a formar parte de la Plataforma Wot </span> y tiene la posibilidad de <span className='negrita'> contar con el sello que le distingue en su compromiso con la diversidad y equidad de género.</span> Con esta membresía, la empresa puede hacer directamente búsquedas ilimitadas de mujeres Wot para las diferentes necesidades u opcines de participación que tenga:</CuadradoTexto61>
        <CuadradoTexto7> CONTRATACION LABORAL</CuadradoTexto7>
        <CuadradoTexto7> SUMAR TALENTO A ALGÚN PROYECTO</CuadradoTexto7>
        <CuadradoTexto7> PARTICIPACIÓN EN COMITÉS</CuadradoTexto7>
        <CuadradoTexto7> DIRECTORIOS O MESAS DE TRABAJO</CuadradoTexto7>
        <CuadradoTexto7> ACTIVIDADES PRO BONO</CuadradoTexto7>
        <label className='mail1'>Si te interesa WOT para tu empresa contactar a alejandra@womantalent.cl</label>
      </div>
      <div> 
        <div className='vlc3'></div>
        <img src={preferirnos} className="Rubia-foto" alt='rubia' />
        <img src={wot11} alt='puzle' className='wot11_2'></img>
        <img src={wot13} alt='logo de fondo' className='wot13'></img>
        <p className='preferirnos1'>POR QUÉ PREFERIRNOS </p>
        <CuadradoTexto2 >A partir de distintas necesidades del mercado, se crea esta plataforma cuyo 
        <span className='negrita' > atributo diferenciador es ofrecer un espacio de conexión y búsqueda directa entre empresas/organizaciones y mujeres profesionales, </span>
        facilitando el acceso a datos categorizados; sin intermediarios, en menor tiempo y costo. </CuadradoTexto2>
        <div></div>
        <CuadradoTexto3 >Simplifica el reclutamiento y aumenta tu visibilidad.</CuadradoTexto3>
        <a name='quienessomos'></a>
      </div>
      
      <Container className='quienesSomos'>
      <div className='vlc4'></div>
        <img src={wot4} className='wot4' alt='logo quienes somos'></img>
        <p className='mujeres5'> QUIÉNES SOMOS</p>
        <FlexContainer>
        <div>
        <img src={fotoSilvia} className="silvia" alt="silvia" />
          <Post>
          <Titulo> Silvia Baeza</Titulo>
          <hr className='hl'></hr>
          <label> Abogada y Magister en Derecho de la Empresa </label>
          <label> Gran experiencia en temas legislativos, regulatorios, relaciones corporativas, y análisis jurídico estratégico </label>
          <CirculoSilvia>in</CirculoSilvia>
          </Post>
          </div>
        <div>
          <img src={fotoCata} className="cata" alt="cata" />
          <Post>
          <Titulo> Catalina Braun</Titulo>
          <hr className='hl'></hr>
          <label>Economista</label>
          <label> Dedicada al mundo de la educación y con gran experiencia en análisis estratégico y gestión. Videcana de la Facultad e Economía y Negocios de la UDD y directora de instituciones educacionales</label>
          <CirculoCata>in</CirculoCata>
          </Post>
          
        </div>
        <div>
          <img src={fotoCarolina} className="carolina" alt="carolina" /> 
          <Post>
          <Titulo> Carolina Eterovic</Titulo>
          <hr className='hl'></hr>
          <label>Ing. Agrónoma</label>
          <label> Experiencia en el ámbito público y privado en temas de equidad, empoderamiento de la mujer, emprendimiento y ESG a traves de estudios especializados y experiencia aplicada </label>
          <label> Socia fundadora de Mujeres Empresarias. </label>
          <CirculoCarolina>in</CirculoCarolina>
          </Post>
        </div>
        <div>
          <img src={fotoFca} className="francisca" alt="francisca" />
          <Post>
          <Titulo>Francisca Valdés </Titulo>
          <hr className='hl'></hr>
          <label>Relacionadora Pública, Co-Fundadora Mujeres Empresarias, VicePresidenta USEC, Consejera Fundación Superación para la Pobreza, irectora National Museum of Women in the Arts, Socia International Women's Forum, Embajadora women Economic Forum. </label>
          <CirculoFca>in</CirculoFca>
          </Post>
        </div>
        </FlexContainer>
      </Container>

      <div>
        <img src={foto2} className='Foto2' alt='grupo mujeres 2' />
        <img src={foto1} className='Foto1' alt='grupo mujeres 1' />
        <img src={wot14} className='wot14' alt='manos juntas' />
        <div className='vl'></div>
        <CuadradoTexto>Dirigido a mujeres profesionales y líderes  diferentes ámbitos, que buscan ser un aporte en el mundo público y privado de nuestro país</CuadradoTexto>
      </div>
      <div>
        <img src={wot15} className='wot16' alt='boton inscripcion'></img>
        <a id="Button-buscar2" href="https://forms.gle/tZmTiFyfXf6UVRDd9">Inscríbete AQUÍ</a>
        {/* <img src={click} className='wot21' alt='flecha'></img> */}
        <img src={foto3} className='Foto3' alt='grupo mujeres 2' />
        <img src={foto4} className='Foto4' alt='grupo mujeres 1' />
        <label className='hashtag6'>#soywot</label>
      </div> 
    </div>
  );
}
const Container = styled.div`
    margin: 0px auto 0;
    text-align: center;
    
`;
const FlexContainer = styled.div`
    display: flex;
    
    flex-flow: row wrap;
    justify-content: space-around;
    margin-left: 12%;
    margin-top: 4%;
    @media screen and (max-width: 480px){
      margin-top: 8vw;
    }
`;
const Post = styled.div`
    display: flex;
    box-shadow: 1px 1px 3px gray;
    border-radius: 10px;
    flex-direction: column;
    background-color:lightgray;
    margin: 0.1%;
    padding: 1%;
    width: 250px;
    height: 300px;
    margin-top:-7%;
    @media screen and (max-width: 480px){
      
    }
`;
const Titulo = styled.h1`
    font-size: 1.5em;
    text-align: center;
    margin-bottom: -1%;
    margin-top:12%;
    
`;

const CirculoCata = styled.h1`
    min-width: 17%; 
    min-height: 14.5%;
    max-width: 17%; 
    max-height: 14.5%;
    background-color: #FF3864; 
    color: white;
    border-radius: 100%;
    align: center;
    margin-left: 43%;
    margin-top: 14%;
    @media screen and (max-width: 480px){
      display:none;
    }
    @media screen and (min-width: 500px) and (max-width: 1024px) {
      display:none;
    }
`;
const CirculoSilvia = styled.h1`
    min-width: 17%; 
    min-height: 14.5%;
    max-width: 17%; 
    max-height: 14.5%;
    background-color: #FF3864; 
    color: white;
    border-radius: 100%;
    align: center;
    margin-left: 43%;
    margin-top: 37%;
    @media screen and (max-width: 480px){
      display:none;
    }
    @media screen and (min-width: 500px) and (max-width: 1024px) {
      display:none;
    }
`;
const CirculoCarolina = styled.h1`
    min-width: 17%; 
    min-height: 14.5%;
    max-width: 17%; 
    max-height: 14.5%;
    background-color: #FF3864; 
    color: white;
    border-radius: 100%;
    align: center;
    margin-left: 43%;
    margin-top: 7%;
    @media screen and (max-width: 480px){
      display:none;
    }
    @media screen and (min-width: 500px) and (max-width: 1024px) {
      display:none;
    }
`;
const CirculoFca = styled.h1`
    min-width: 17%; 
    min-height: 14.5%;
    max-width: 17%; 
    max-height: 14.5%;  
    background-color: #FF3864; 
    color: white;
    border-radius: 100%;
    align: center;
    margin-left: 43%;
    margin-top: 7%;
    @media screen and (max-width: 480px){
      display:none;
    }
    @media screen and (min-width: 500px) and (max-width: 1024px) {
      display:none;
    }
`;
const CuadradoTexto =styled.div`
    background-color: white; 
    margin-top:5vw;
    margin-left: 12vw; 
    width: 35vw;
    font-size: 1.9vw;
    text-align: left;
    @media screen and (max-width: 480px){
      display:none;
    }
    @media screen and (min-width: 500px) and (max-width: 1024px){
      width: 80vw;
      text-align: justify;
    }
`;
const CuadradoTexto2 = styled.div`
    width: 35vw;
    font-size: 2vw;
    text-align: justify;
    margin-left: 17vw;
    margin-top: 4vw;
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
    }
`;
const CuadradoTexto3 = styled.div`
    width: 35vw;
    font-size: 2vw;
    text-align: left;
    margin-left: 17vw;
    color: #FF3864;
    font-weight: bold;
    margin-top: 3vw;
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
    }

`;
const CuadradoTexto5 = styled.div`
    width: 35vw;
    font-size: 2vw;
    text-align: justify;
    margin-left: 19vw;
    margin-top: -45vw; 
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
    }

    
`;
const CuadradoTexto4 = styled.div`
    width: 35vw;
    font-size: 2vw;
    text-align: justify;
    margin-left: 17vw;
    margin-top: -48vw; 
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
      margin-left: 19vw;
      margin-top:-45vw;
    }
    
`;
const CuadradoTexto6 = styled.div`
    width: 35vw;
    font-size: 2vw;
    text-align: justify;
    margin-left: 17vw;
    margin-top: -45vw; 
    margin-bottom: 25vw;
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
    }
`;
const CuadradoTexto61 = styled.div`
    width: 45vw;
    font-size: 2vw;
    text-align: justify;
    margin-left: 17vw;
    margin-top: -48vw; 
    margin-bottom: 25vw;
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
    }
`;
const CuadradoTexto62 = styled.div`
    width: 45vw;
    font-size: 1vw;
    text-align: justify;
    margin-left: 17vw;
    margin-top: -24vw; 
    margin-bottom: 15vw;
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
    }
`;
const CuadradoTexto7 = styled.div`
    width: 40vw;
    font-size: 2vw;
    text-align: justify;
    margin-left: 20vw;
    margin-top: -24vw;
    margin-bottom: 25vw;
    font-weight: bold;
    color: #00b4cc;
    @media screen and (max-width: 480px){
      font-size:3vw;
      width:70vw;
      color: #E5007e;
    }
`;


export default App;
