import '../empresas.css';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Header from '../componentes/Header';


const AgregarUsuario = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate()
    const jwtToken = localStorage.getItem('accessToken');
    const [idEmpresa, setIdEmpresa] = useState('');
    const [email , setEmail] = useState('');
    const [cargo , setCargo] = useState('');
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/get-all`, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            const data = response.data;
            if (Array.isArray(data)) {
              console.log(data);
              setCompanies(data.empresas);
            }
            else if (typeof data === 'object') {
              console.log(data.empresas);
              setCompanies(data.empresas);
            }
          })
          .catch(error => console.error(error));

    }, [jwtToken]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const datos = {
            id: idEmpresa, // Asumiendo que 'idEmpresa' contiene el ID correcto para 'id'
            users: [{
                name: username,
                email: email,
                user_cargo: cargo,
                password: password
            }]
        };
        console.log(datos);
        axios.put(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/users`, datos, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            navigate('/perfiladmin');
        })
        .catch(error => {
            console.error(error);
            // Asegurarse de que existe error.response y error.response.data antes de acceder a error.response.data.error
            setErrorMessage(error.response && error.response.data ? error.response.data.error : "An error occurred");
        });
    };


    return (
    <div className="WT1">
        <Header /> 
        <div className='containerempresas'>
            <form onSubmit={handleSubmit}>
            <div className='textoLogin-admin'>Agregar Usuario Empresa</div> 

                <select className='inputSelect' onChange={e => setIdEmpresa(e.target.value)}>
                    <option value=''>Selecciona una empresa</option>
                    {companies.map(company => (
                        <option key={company.id} value={company.id}>{company.nombre}</option>
                    ))}
                </select>
                <div className='nombreUsuario'>
                    <label> Nombre </label>
                    <div></div>
                    <input
                        className='input1'
                        name="name"
                        type="text"
                        onChange={e => setUsername(e.target.value)}
                    />
                </div> 
                <div className='emailUsuario'>
                    <label> Email </label>
                    <div></div>
                    <input
                        className='input1'
                        name="email"
                        type="text"
                        onChange={e => setEmail(e.target.value)}
                    />
                </div> 
                <div className='cargoUsuario'>
                    <label> Cargo </label>
                    <div></div>
                    <input
                        className='input1'
                        name="user_cargo"
                        type="text"
                        onChange={e => setCargo(e.target.value)}
                    />
                </div> 
                <div className='contrasenaUsuario'>
                    <label>Contraseña</label>
                    <div></div>
                    <input
                        className='input1'
                        name="nombre"
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" id = "enviar">Enviar</button>
                {errorMessage && <div className='error-message'>{errorMessage}</div>}
                </form>
   
        </div>
        <div className='rectangulo'></div>
        <img src={wot24} alt='circulo' className='wot24' />
    </div>
    )
}

export default AgregarUsuario;