import React, { useEffect, useState } from 'react';
import axios from 'axios';
import wot20 from '../../iconos/wot20.png';
import wot24 from '../../iconos/wot24.png';
import Header from '../../componentes/Header';

const Ponderadores = () => {
  const [relacionesProfesion, setRelacionesProfesion] = useState([]);
  const [updateMessage, setUpdateMessage] = useState('Puedes actualizar los valores de las ponderaciones.');
  const [showUpdateMessage, setShowUpdateMessage] = useState(true);
  const [profesiones, setProfesiones] = useState([]);
  const jwtToken = localStorage.getItem('accessToken');
  // console.log(jwtToken);

  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_profesiones', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setRelacionesProfesion(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener relaciones de profesiones', error);
      });
  }, [jwtToken]);


  //Endpoint profesiones 'https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_profesione/profesiones
  useEffect(() => {
    axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_profesiones/profesiones', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setProfesiones(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener profesiones', error);
      });
  }, [jwtToken]);

  //Mapeo las relaciones 
  const relacionMatrix = {};
  relacionesProfesion.forEach(relacion => {
    const key = `${relacion.profesionA.nombre}-${relacion.profesionB.nombre}`;
    relacionMatrix[key] = relacion;
  });

  const handleActualizarClick = async (profesionA, profesionB, nuevaPonderacion) => {
    // Busca las profesiones correspondientes en el arreglo de relacionesProfesion


    //REvisar si la relacion existe o no, e identificarla. 
    const relacionExistente = relacionesProfesion.find(rel => { 
      return (rel.profesionA.id === profesionA && rel.profesionB.id === profesionB) ||
              (rel.profesionA.id === profesionB && rel.profesionB.id === profesionA);
    });

    // Verificar si la relación ya existe
    if (relacionExistente) {
      // Si la relación ya existe, no es necesario enviar el ID existente
      console.log("Relacion existe");
      axios
        .put(
          `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_profesiones/${relacionExistente.id}`,
          { nuevaPonderacion },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const updatedRelacionesProfesion = relacionesProfesion.map((rel) => {
            if (rel.id === relacionExistente.id) {
              return { ...rel, ponderacion: nuevaPonderacion };
            }
            return rel;
          });
          setRelacionesProfesion(updatedRelacionesProfesion);
          setUpdateMessage('PONDERADOR ACTUALIZADO CORRECTAMENTE!');
          setShowUpdateMessage(true);
          // Ocultar el mensaje después de 3 segundos (3000 milisegundos)
          setTimeout(() => {
            setShowUpdateMessage(true);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        })
        .catch((error) => {
          console.error('Error al actualizar ponderación', error);
          setUpdateMessage('Error al actualizar ponderador.');
        });
      } else {
        // Si la relación no existe, crea una nueva relación
        console.log("Relacion no existe");
      
        try {
          await axios.post(
            `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_profesiones`,
            { id_profesion_a: profesionA, id_profesion_b: profesionB, nuevaPonderacion },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
      
          const response = await axios.get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/relaciones_profesiones', {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          });
      
          setRelacionesProfesion(response.data);
          setUpdateMessage('Nueva relación creada correctamente!');
          setShowUpdateMessage(true);
          setTimeout(() => {
            setShowUpdateMessage(false);
            setUpdateMessage('Puedes actualizar los valores de las ponderaciones.');
          }, 3000);
        } catch (error) {
          console.error('Error al crear nueva relación:', error);
          setUpdateMessage('Error al crear nueva relación.');
          setShowUpdateMessage(true);
        }
      };
  }

      
  

  return (
    <div className="WT1">
      <Header />  

      <div className="ponderadores-container">
        <p className="boldMiPerfil">Relaciones de Profesiones</p>
        {showUpdateMessage && <p className='update-message'>{updateMessage}</p>}
        <table className="ponderadores-table">
          <thead>
            <tr>
              <th></th>
              {profesiones.map((profesion, index) => (
                <th key={index} className="header">{profesion}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {profesiones.map((profesionA, rowIndex) => (
              <tr key={rowIndex}>
                <th className="header">{profesionA}</th>
                {profesiones.map((profesionB, colIndex) => (
                  <td key={colIndex}>
                    {rowIndex <= colIndex ? 
                      // Dentro del componente Ponderadores
                          ((profesionA === profesionB) ? '1' : (
                            <input
                              type="number"
                              className="ponderacion-input"
                              value={(relacionMatrix[`${profesionA}-${profesionB}`] || {}).ponderacion || ''}
                              onChange={(e) => {
                                const newValue = parseFloat(e.target.value);
                                if (!isNaN(newValue) && newValue >= 0 && newValue <= 1) {
                                  const profesionAId = relacionesProfesion.find(rel => rel.profesionA.nombre === profesionA).profesionA.id;
                                  const profesionBId = relacionesProfesion.find(rel => rel.profesionB.nombre === profesionB).profesionB.id;
                                  handleActualizarClick(profesionAId, profesionBId, newValue);
                                }
                              }}
                              min="0"
                              max="1"
                              step="0.1"
                            />
                          ))
                          : ''}
                      </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="rectanguloEmpresas"></div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default Ponderadores;