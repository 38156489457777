import './formularioEmpresas.css';
import '../busquedas/busquedas.css';
import '../empresas.css';
import '../usuarias.css';
import wot20 from '../iconos/wot20.png';
import wot23 from '../iconos/wot24.png';
import wot_inicio from '../img/brochure wot final iniccio.png'
import FormularioEmpresa1 from './formularioEmpresa1';
import ReactDOM from 'react-dom';
const Inicio = () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root')
      );
    function formularioEmpresa1() {
        root.render(<FormularioEmpresa1/>);
        }
    return (
    <div className="WT-busquedas">
        <header>
        <nav className='topnavbar-busquedas'>
              <img href='/' src={wot20} alt='logo wot'/>
        </nav>
        </header>
        <div className='container-busquedas'>
            <label className='texto1-busquedas'>¡Bienvenida al formulario de inscripción!</label> 
            <div></div>
            <img src={wot_inicio} className="WT-logo-busquedas" alt="primera foto" />
            <div></div>
            <label className='texto3-busquedas'>En este formulario te preguntamos lo necesario para crear un perfil de empresa</label>
            <div></div>
            <label className='texto4-busquedas'>Las preguntas con un <span className='rojo'> asterisco(*)</span> son obligatorias.</label>
            <button id='empezar-busquedas' onClick={(e) => {formularioEmpresa1()} }> Empezar! </button>
        </div>
        <div className='rectangulo-busquedas'></div>
        <img src={wot23} alt='circulo' className='wot23-busquedas' />
        <progress id="barra" max="100" value="0"> 0% </progress> 
        <label className='porcentaje'>0%</label>
    </div>
    )
    
}

export default Inicio